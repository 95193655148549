<template>
  <section id="massages-relaxants" class="row mab-1">
    <div  class="col col-6 md-show hide par-md-1 bg_img_profond">
<!--      <img :src="imageProfond" alt="Image massage profonds">-->
    </div>
    <div  class="col col-12 col-md-6 pal-md-1">
      <h3>Massages profonds</h3>
      <p class="center">
        Massages profonds, soulagent tensions musculaires, améliorent flexibilité. Aident à traiter douleurs chroniques, améliorent posture. Idéals pour pression forte, tensions spécifiques.
      </p>
      <table class="priceTable">
        <thead>
          <tr>
            <th></th>
            <th>Durée</th>
            <th>Tarif</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-bottom">
            <th>Massage Drainage lymphatique</th>
            <td>60 m<span>inutes</span></td>
            <td>60 €</td>
          </tr>
          <tr class="border-bottom">
            <th>Massage Sportif</th>
            <td>60 m<span>inutes</span></td>
            <td>60 €</td>
          </tr>
          <tr>
            <th>Massage Dos</th>
            <td>60 m<span>inutes</span></td>
            <td>55 €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import imageProfond from '@/assets/Images/gros-plan-homme.jpg';
export default {
  data() {
    return ({
      imageProfond,
    })
  }
}
</script>