<template>
  <header :class="{ scrolled}">
    <nav :class="navClass">
      <div class="logo">
        <SmoothLink :to="'/'">
<!--          <img-->
<!--            :src="LogoChampa"-->
<!--            alt="Logo champa massage"-->
<!--          >-->
          <LogoChampa></LogoChampa>
        </SmoothLink>
      </div>
      <menu>
        <div class="mobile">
          <button @click="openMenu" class="hamburger-button">
            ☰
          </button>
          <ul :class="{'mobile-menu': true, isMenuOpen: isMenuOpen}" >
            <li>
              <button @click="closeMenu" class="hamburger-button">
                ☰
              </button>
            </li>
            <li>
              <SmoothLink
                  :offset="-80"
                  :duration="500"
                  to="/#carte-massages"
                  @click="closeMenu"
              >
                CARTE DES MASSAGES
              </SmoothLink>
            </li>
            <li>
              <SmoothLink
                  :offset="-80"
                  to="/#gallerie_link"
                  @click="closeMenu"
              >
                GALERIE
              </SmoothLink>
            </li>
            <li>
              -<SmoothLink
                @click="closeMenu"
                to="/credits">CRÉDITS</SmoothLink>
            </li>
            <li>
              <SmoothLink
                  @click="closeMenu"
                  to="/informations-legales">INFORMATIONS LÉGALES</SmoothLink>
            </li>
            <li class="pictoMobile">
              <a href="https://www.facebook.com/MassageChampha" target="_blank"  @click="closeMenu">
                <img :src="LogoFB" alt="Logo Facebook">
              </a>
              <a href="https://www.instagram.com/champa_massage67/" target="_blank"  @click="closeMenu">
                <img :src="LogoInsta" alt="Logo Instagram">
              </a>
            </li>
          </ul>
        </div>
        <ul>
          <li>
            <SmoothLink
              :offset="-80"
              :duration="500"
              to="/#carte-massages"
            >
              CARTE DES MASSAGES
            </SmoothLink>
          </li>
          <li>
            <SmoothLink
              :offset="-80"
              to="/#gallerie_link"
            >
              GALERIE
            </SmoothLink>
          </li>
          <li class="picto">
            <a href="https://www.facebook.com/MassageChampha" target="_blank">
              <img :src="LogoFB" alt="Logo Facebook">
            </a>
          </li>
          <li class="picto">
            <a href="https://www.instagram.com/champa_massage67/" target="_blank">
              <img :src="LogoInsta" alt="Logo Instagram">
            </a>
          </li>
        </ul>
      </menu>
    </nav>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import SmoothLink from '../components/SmoothLink';
// import LogoChampa from '@/assets/Images/Champa S.png';
import LogoFB from '@/assets/Images/logo-bleu-officiel-facebook.webp';
import LogoInsta from '@/assets/Images/logo_instagram2.png';
import LogoChampa from '@/components/LogoChampa.vue';

export default {
  data() {
    return ({
      scrolled: false,
      LogoFB,
      LogoInsta,
      isMenuOpen: false

    });
  },
  components: {
    SmoothLink,
    LogoChampa,
  },
  watch: {
    '$route': {
      immediate: true,
      handler: 'handleScroll'
    }
  },
  methods: {
    handleScroll() {
      const offset = window.scrollY;
      this.scrolled = offset > 0;
    },
    openMenu() {
      this.isMenuOpen = true;
      document.body.classList.add('no-scroll');
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.classList.remove('no-scroll');
    }
  },
  computed: {
    navClass() {
      const route = useRoute();
      return (['container', (route.path === '/' ? 'home' : route.path.replace('/', ''))].join(' '));
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>


<style scoped lang="scss">
@import "@/styles/_variables.scss";

.hamburger-button {
  /* Appliquez le style pour votre bouton ici (ex: couleur, bordure, etc.) */
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.mobile-menu {
  /* Style pour votre menu mobile lorsqu'il est ouvert */
  list-style-type: none;
  padding: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  /* height: 100vh; */
  backdrop-filter: blur(3px);
  background-color: rgba(255,255,255,0.6);
  border: 1px solid #ccc;
}

.mobile-menu li {
  padding: 30px;
  border-bottom: 1px solid #eee;
}

.mobile-menu li:last-child {
  border-bottom: none;
}

.mobile-menu a {
  text-decoration: none;
  color: #333;
}

.mobile-menu {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.mobile-menu.isMenuOpen {
  pointer-events: all;
  opacity: 1;
}

</style>