<template>
  <div class="gallerie">
    <Carousel
        v-bind="settings" :breakpoints="breakpoints"

    >
      <!--        id="thumbnails"-->
      <!--        :items-to-show="images.length"-->
      <!--        :wrap-around="false"-->
      <!--        v-model="currentSlide"-->
      <!--        ref="carousel"-->
      <Slide v-for="(image, index) in images" :key="index">
        <div class="carousel__item" @click="slideTo(index)">
          <img :src="image" alt="Image 1">
        </div>
      </Slide>
      <template #addons>
        <Pagination />
        <Navigation />
      </template>
    </Carousel>
<!--    <Carousel id="gallery" :items-to-show="1" :wrap-around="false" v-model="currentSlide" v-if="currentSlide !== null">-->
<!--      <slide v-for="(image, index) in images" :key="index">-->
<!--        <div>-->
<!--          <img :src="image" alt="Image 1">-->
<!--        </div>-->
<!--      </slide>-->
<!--    </Carousel>-->
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'

import img1 from '@/assets/Images/Salon_1.jpg';
import img2 from '@/assets/Images/Salon_2.jpg';
import img3 from '@/assets/Images/Salon_3.jpg';
import img4 from '@/assets/Images/Salon_4.jpg';
import img5 from '@/assets/Images/Salon_5.jpg';
import img6 from '@/assets/Images/Salon_6.jpg';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return ({
      currentSlide: null,
      images: [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        // 700: {
        //   itemsToShow: 3.5,
        //   snapAlign: 'center',
        // },
        // // 1024 and up
        // 1024: {
        //   itemsToShow: 1,
        //   snapAlign: 'start',
        // },
      },
    });
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
  },
}
</script>

<style>
.gallerie {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallerie > .carousel {
  max-width: 500px;
}
.carousel__item {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel__item img {
  height:100%;
}
</style>