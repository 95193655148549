<template>
  <router-link
    :to="to"
    @click="handleClick"
  >
    <slot />
  </router-link>
</template>

<script>

export default {
  props: {
    to: {
      type: String,
      required: true
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler: 'onRouteChange'
    }
  },
  methods: {
    handleClick(ev) {
      this.$nextTick(() => {
        const [path, hash] = this.to.split('#');

        if (hash !== undefined) {
          const element = document.getElementById(hash);
          if (element) {
            const elementPosition = element.offsetTop;
            const offsetPosition = elementPosition + this.offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    },
    onRouteChange() {
      // window.addEventListener('load', () => {
      const [path, hash] = this.to.split('#');

      if (this.$route.path === path) {
        if (hash !== undefined && this.$route.hash === `#${hash}`) {
          const element = document.getElementById(hash);
          if (element) {
            const elementPosition = element.offsetTop;
            const offsetPosition = elementPosition + this.offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }
        } else if (hash === undefined) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
      // });
    },
  },
};
</script>
