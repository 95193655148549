<template>
  <div>
    <section id="massages-relaxants" class="row mab-1">
      <div  class="col col-12 col-md-6 par-md-1">
        <h3>Massages relaxants</h3>
        <p class="center">
          Massages relaxants favorisent le lâcher-prise, réduisent stress, améliorent la circulation. Procurent calme, bien-être.
        </p>

        <table class="priceTable">
          <thead>
          <tr>
            <th></th>
            <th>Durée</th>
            <th>Tarif</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th rowspan="2">Massage Relaxant California</th>
            <td>60 m<span>inutes</span></td>
            <td>50 €</td>
          </tr>
          <tr class="border-bottom">
<!--            <th>Massage Relaxant California</th>-->
            <td>90 m<span>inutes</span></td>
            <td>70 €</td>
          </tr>
          <tr class="border-bottom">
            <th>Massage Bougie</th>
            <td>60 m<span>inutes</span></td>
            <td>55 €</td>
          </tr>
          <tr>
            <th rowspan="2">Massage Signature Huile Chaude</th>
            <td>60 m<span>inutes</span></td>
            <td>55 €</td>
          </tr>
          <tr>
<!--            <th>Massage Signature Huile Chaude</th>-->
            <td>90 m<span>inutes</span></td>
            <td>75 €</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div  class="col col-6 md-show hide bg_img_relaxant">
<!--        <img :src="imageRelaxant" alt="image massage relaxant"/>-->
      </div>
    </section>
  </div>
</template>

<script>
import imageRelaxant from '@/assets/Images/jeune-femme-se-detendre-dans-salon-spa.jpg';
export default {
  data() {
    return ({
      imageRelaxant,
    })
  }
}
</script>