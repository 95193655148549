<template>
  <footer>
    <p>&copy; 2023 Champa Massage
      -<SmoothLink to="/informations-legales">Informations Légales</SmoothLink>
      -<SmoothLink to="/credits">Crédits</SmoothLink></p>
  </footer>
</template>

<script>
import SmoothLink from '@/components/SmoothLink.vue';
export default {
  components: {
    SmoothLink,
  },
};
</script>