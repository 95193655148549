import { createRouter, createWebHistory } from 'vue-router'
import PageAccueil from '@/pages/PageAccueil.vue';
import PageMentionsLegales from '@/pages/PageMentionsLegales.vue';
import Credits from '@/pages/Credits.vue';

const routes = [
    {
        path: '/',
        name: 'PageAccueil',
        component: PageAccueil,
    },
    {
        path: '/informations-legales',
        name: 'PageMentionsLegales',
        component: PageMentionsLegales
    },
    {
        path: '/credits',
        name: 'Credits',
        component: Credits
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


export default router
