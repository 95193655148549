<template>
  <section id="massages-relaxants" class="row mab-1">
    <div  class="col col-12 col-md-6 par-md-1">
      <h3>Massages spécifiques</h3>
      <p class="center">
        Massages spécifiques, adaptés à vos besoins. Pour tensions spécifiques ou condition particulière.
        Discussions préalables pour établir plan de traitement. Idéal pour problèmes spécifiques.
      </p>

      <table class="priceTable">
        <thead>
        <tr>
          <th></th>
          <th>Durée</th>
          <th>Tarif</th>
        </tr>
        </thead>
        <tbody>
        <tr class="border-bottom">
          <th>Massage Cranien</th>
          <td>30 m<span>inutes</span></td>
          <td>30 €</td>
        </tr>
        <tr class="border-bottom">
          <th>Massage Spécial Femme Enceinte</th>
          <td>60 m<span>inutes</span></td>
          <td>50 €</td>
        </tr>
        <tr class="border-bottom">
          <th>Massage Assis sur Chaise</th>
          <td>30 m<span>inutes</span> </td>
          <td>25 €</td>
        </tr>
        <tr class="border-bottom">
          <th>Réflexologie Plantaire Esthétique</th>
          <td>60 m<span>inutes</span></td>
          <td>50 €</td>
        </tr>
        <tr>
          <th>Soin de visage manuel japonais</th>
          <td>60 m<span>inutes</span></td>
          <td>50 €</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div  class="col col-6 md-show hide bg_img_specifique">
<!--      <img :src="imageSpecifique" alt="Imager massage specifique">-->
    </div>
  </section>
</template>

<script>
import imageSpecifique from '@/assets/Images/choyer-massage-pour-beau-visage-jeune-femme-dans-salon-spa.jpg';
export default {
  data() {
    return ({
      imageSpecifique,
    })
  }
}
</script>