<template>
  <main id="top">
    <section
      id="accueil"
      class='image-container mab-1'
    >
      <div class="parallax"></div>
      <div class="bg"></div>
      <div class="image-container-content">
        <h1> Salon de massage bien être </h1>
        <p> OFFREZ-VOUS UN MOMENT DE DÉTENTE DANS UN ENVIRONNEMENT CALME ET APAISANT À PROXOMITÉ DE STRASBOURG</p>
      </div>
      <div class="custom-shape-divider-bottom-1689895869">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
      </div>
    </section>
    <section id="carte-massages" class="container">
      <h2>CARTE DES MASSAGES</h2>
      <p class="mab-1">
        N'oubliez pas : bien que nos massages puissent aider à favoriser la relaxation et le bien-être, ils ne sont pas destinés à diagnostiquer, traiter ou prévenir des maladies et ne doivent pas remplacer un traitement médical.
      </p>
      <section class="offre">
        <h3>Offre spéciale</h3>
        <p>
          Découvrez Champa Massage avec notre offre découverte : <br>
          Massage profond du dos (30 min) + massage crânien et massage relaxant visage manuel japonais (30min) pour seulement 65€ !      </p>

      </section>
      <SectionRelaxant></SectionRelaxant>
      <SectionProfonds></SectionProfonds>
      <SectionSpecifique></SectionSpecifique>
    </section>
    <section id="gallerie">
      <div class="custom-shape-divider-top-1690640713">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <div class="container" id="gallerie_link">
        <h2>Galerie</h2>
        <SectionGallerie></SectionGallerie>
        <br>
        <br>
      </div>
      <div class="custom-shape-divider-bottom-1690640865">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
    </section>
    <section id="contact" class="container">
      <h2>Contact</h2>
      <p>
        Pour d'autres informations ou pour prendre rendez-vous, vous pouvez me contacter par
        <span @click="sendMail" class="link">Email</span>
        ou par <span @click="callNumber" class="link">Téléphone</span>
        ou encore sur <a target="_blank" href='https://www.facebook.com/MassageChampha'>Facebook</a>
      </p>
    </section>
  </main>
</template>

<script>
// import bgImage from '@/assets/Images/Salon champa.jpg';
import SectionSpecifique from '@/pages/SectionsAccueil/SectionSpecifique.vue';
import SectionProfonds from '@/pages/SectionsAccueil/SectionProfonds.vue';
import SectionRelaxant from '@/pages/SectionsAccueil/SectionRelaxant.vue';
import SectionGallerie from '@/pages/SectionsAccueil/SectionGallerie.vue';
export default {
  components: {
    SectionSpecifique,
    SectionRelaxant,
    SectionProfonds,
    SectionGallerie,
  },
  data() {
    return ({
      // bgImage: bgImage,
    })
  },
  methods: {
    callNumber() {
      const link = document.createElement('a');
      link.href = "tel: +33631527178"; // Remplacez par votre numéro
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    sendMail() {
      const link = document.createElement('a');
      link.href = "mailto: luang.pheng@gmail.com"; // Remplacez par votre numéro
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Default Title',
    // all titles will be injected into this template
    titleTemplate: '%s | My Awesome Webapp'
  }
}
</script>