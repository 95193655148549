<template>
  <main>
    <section
        id="mentions-legales"
        class='image-container mab-1'
    >
      <div class="parallax"></div>
      <div class="bg"></div>
      <div class="custom-shape-divider-bottom-1689895869">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
      </div>
    </section>
    <section class='container'>
      <h1>Informations légales</h1>

      <h2>IDENTITÉ DE L'ÉDITEUR</h2>
      <p>
        <b>Nom de l'entreprise :</b> ChampaMassage <br/>
        <b>Statut :</b> Auto-entrepreneur <br/>
        <b>SIRET :</b> 923 297 105 00010 <br/>
        <b>Adresse / Siège social :</b> 5 rue des Orchidées Ittenheim 67117 <br/>
        <b>Site internet :</b> champamassage.fr <br/>
        <span @click="sendMail" class="link">Email</span><br/>
        <span @click="callNumber" class="link">Téléphone</span>
      </p>

      <h2> HÉBERGEMENT </h2>
      <p>
        <b>Site hébergé par :</b> <a href="ovh.fr">ovh.fr</a> <br>
        <b>Adresse de l'hébergeur :</b> 2 rue Kellermann - 59100 Roubaix - France
      </p>
      <h2> CONDITIONS D'UTILISATION </h2>
      <p> L'utilisateur du site internet reconnaît disposer de la compétence et des moyens nécessaires pour accéder et utiliser ce site. Il reconnaît également avoir pris connaissance de la présente notice légale et s'engage à la respecter.  </p>
      <h2> INFORMATIONS TECHNIQUES </h2>
      <p>
        Il est rappelé que le secret des correspondances n'est pas garanti sur le réseau Internet et qu'il appartient à chaque utilisateur d'Internet de prendre toutes les mesures appropriées de façon à protéger ses propres données et/ou logiciels de la contamination d'éventuels virus circulant sur Internet.
      </p>
      <h2> DONNÉES PERSONNELLES </h2>
      <p>
        Conformément au Règlement général sur la protection des données (RGPD), vous disposez d'un droit d'accès, de rectification et de suppression des données personnelles qui vous concernent. Pour exercer ce droit, vous pouvez vous adresser par courrier électronique à : [Adresse email de votre entreprise]
      </p>
      <h2> CONTENU DU SITE </h2>
      <p>
        Nous nous efforçons de tenir les informations diffusées sur ce site à jour. Cependant, nous ne garantissons ni l'exactitude, ni la complétude de toute information présentée ici.
      </p>
      <h2> SERVICES DE MASSAGE </h2>
      <p>
        Nos services de massage sont destinés à favoriser la relaxation et le bien-être. Ils ne sont pas destinés à diagnostiquer, traiter ou prévenir des maladies. Ils ne remplacent pas un traitement médical. En cas de doute, veuillez consulter votre médecin.
      </p>
    </section>
  </main>
</template>
<script setup>
</script>

<script>
export default {
  methods: {
    callNumber() {
      const link = document.createElement('a');
      link.href = "tel: +33631527178"; // Remplacez par votre numéro
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    sendMail() {
      const link = document.createElement('a');
      link.href = "mailto: luang.pheng@gmail.com"; // Remplacez par votre numéro
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
}
</script>
